import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ViewportProps } from 'react-map-gl'

export type MapState = {
  viewport: ViewportProps
  displayToolbar: string
}

const initialState: MapState = {
  viewport: {
    latitude: 46.8025,
    longitude: 2.7643,
    zoom: 5.4389429465554,
    bearing: 0,
    pitch: 0,
  },
  displayToolbar: '',
}

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    updateViewport: (state, action: PayloadAction<ViewportProps>) => {
      state.viewport = action.payload
    },
    toggleDisplayToolbar: (state, action: PayloadAction<string>) => {
      state.displayToolbar = action.payload
    },
  },
})

export const {
  updateViewport,
  toggleDisplayToolbar,
} = mapSlice.actions

export default mapSlice.reducer
