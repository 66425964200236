/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
    updated: 'Modifié',
    sortBy: 'Trier par',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Une erreur est survenue.',
      serverDown: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
  },
  SideMenu: {
    links: {
      myViz: 'Mes portefeuilles',
      help: 'Importer un portefeuille',
    },
  },
  Pages: {
    Projects: {
      title: 'Mes portefeuilles',
      Cards: {
        actionButton: 'Accéder au portefeuille',
      },
      searchPlaceholder: 'Rechercher',
      dropdownLabels: {
        date: 'Date de modification',
        alpha: 'Ordre alphabétique',
      },
    },
  },
} as const

export default terms
