import { MapRequest } from 'react-map-gl'
import MAIN_API from 'config/config'

/**
 * Replace env in request URL and add token if needed
 *
 * @param url to transform
 * @param resourceType use to check if token is needed
 * @returns MapRequest
 */
export const transformRequest = (url: string, resourceType: string): MapRequest => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    url = url.replace('dev.dgexsol.', newEnv)
  }

  if ((resourceType === 'Source' || resourceType === 'Tile')) {
    return {
      url,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  return { url }
}
