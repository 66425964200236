/* eslint-disable max-len */
import { ReactElement } from 'react'
import { MapEvent, Popup } from 'react-map-gl'
import './style.scss'

type Props = {
    event: MapEvent;
    onClose: (state: boolean) => void;
}
export default function FeatureClickPopup({ event, onClose }: Props): ReactElement {
  const coords = event?.lngLat



  return (
    <Popup
      longitude={coords[0]}
      latitude={coords[1]}
      captureScroll
      onClose={() => onClose(false)}
      className="mapboxgl-hover-custom-popup"
    >
      <div className="mapboxgl-popup-container">
        <div className="popup-content">

          <span>test</span>
        </div>

      </div>

    </Popup>
  )
}
