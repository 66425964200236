import { ReactElement } from 'react'
import { useAppDispatch, useAppSelector } from 'utils'
import { toggleDisplayToolbar } from 'reducers/map/slices'
import { NavigationControl } from 'react-map-gl'
import './style.scss'
import { MENU_ITEMS, MenuKeys } from './const'

export default function Toolbar(): ReactElement {
  const dispatch = useAppDispatch()
  const { displayToolbar } = useAppSelector(state => state.map)

  const toggleDisplayMenu = (key: MenuKeys) => {
    const menuToShow = displayToolbar === key ? '' : key
    dispatch(toggleDisplayToolbar(menuToShow))
  }

  return (
    <>
      <div
        id="map-menu"
        className={` ${displayToolbar ? 'display-menu' : ''}`}
      >
        {MENU_ITEMS(toggleDisplayMenu).map(item => (
          <div
            className="icon-wrapper"
            key={item.key}
            role="button"
            tabIndex={-1}
            onClick={item.onClick}
          >
            {item.icon}
          </div>
        ))}
        <NavigationControl
          compassLabel="compas"
          showZoom={false}
          className="navigation-control"
        />
      </div>
    </>
  )
}
