import config from 'config/config'

const MAP_LAYER_SOURCE = {
  track: 'cassini_v2_gaia_habillage_valeur_margin_100',
}

const LAYER_NAMES = {
  trackLayer: 'track-layer',
  regionLayer: 'region-layer',
  chantierLayer: 'chantier-layer',
}

const LAYER_URL = `${config.proxy}/chartis/v2/layer`


export {
  MAP_LAYER_SOURCE, LAYER_URL, LAYER_NAMES,
}
