import { LAYER_URL } from './const'
import { ViewType } from './types'

const formatLayerUrl = (sourceLayer: string, type: ViewType, params = ''): string => {
  const layerUrl = `${LAYER_URL}/${sourceLayer}/mvt/${type}/?${params}`

  return layerUrl
}

// eslint-disable-next-line import/prefer-default-export
export { formatLayerUrl }
