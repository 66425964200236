import { ReactElement } from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import './style.scss'

type Props = {
  message?: string | undefined;
}

const defaultProps: Props = {
  message: undefined,
}

export default function Loader({ message }: Props): ReactElement {
  return (
    <div className="loader">
      <CircularProgress />
      {message && <div className="m-2">{`${message}...`}</div>}
    </div>
  )
}

Loader.defaultProps = defaultProps
