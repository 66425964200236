import { LAYER_NAMES } from '../const'
import { LayerProperties } from '../types'

const trackFilter = ['any',
  ['==', ['get', 'valeur_libelle'], 'Exploitée'],
  ['==', ['get', 'valeur_libelle'], 'Transférée en voie de service'],
]

const TrackLayerPaint: LayerProperties = {
  trackLayer: {
    id: LAYER_NAMES.trackLayer,
    type: 'line',
    paint: {
      'line-color': '#C0C1CC',
      'line-width': {
        stops: [
          [8, 2],
          [20, 5],
        ],
      },
    },
    filter: trackFilter,
  },
  trackNumberLayer: {
    type: 'symbol',
    id: `${LAYER_NAMES.trackLayer}-number`,
    minzoom: 8,
    layout: {
      'text-font': ['Open Sans Regular'],
      'text-size': 16,
      'symbol-placement': 'line',
      'text-allow-overlap': false,
      'text-anchor': 'top',
      'text-field': '{lrs_ligne}',
    },
    paint: {
      'text-color': '#838383',
    },
    filter: trackFilter,
  },
}

export default TrackLayerPaint
