import {
  ReactElement, StrictMode, Suspense, useEffect, useState,
} from 'react'
import {
  BrowserRouter,
} from 'react-router-dom'
import { auth } from '@osrdata/app_core'
import Loader from 'components/loader'
import TopBar from 'components/topBar'
import CustomSnackbar from 'components/snackbar'
import { terms } from 'common/terms'
import { useAppDispatch, useAppSelector } from 'utils'
import { hideSnackbar } from 'reducers/app'
import { AccessDenied } from 'pages'
import { Roles } from 'types'
import MapGL from 'components/map'
import 'App.scss'

const APP_NAME = 'CARTO SUPPLY'
const ACCESS_PERM = Roles.ACCESS

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const { isLogged, appPermissions, isLoading } = useAppSelector(state => state.user)
  const { displaySnackbar, snackbarMessage, snackbarSeverity } = useAppSelector(state => state.app)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0 && !appPermissions.includes(ACCESS_PERM)) {
      setAccessDenied(true)
    }
  }, [isLogged, appPermissions])

  const renderApp = () => {
    if (isLoading) return <Loader message={terms.Common.loading} />
    if (accessDenied) return <AccessDenied />

    return (
        <MapGL />
    )
  }

  if (!isLoading && !isLogged) return null

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <TopBar appName={APP_NAME} />
          <div id="app">{renderApp()}</div>
          <CustomSnackbar
            message={snackbarMessage}
            severity={snackbarSeverity}
            displaySnackbar={displaySnackbar}
            handleClose={() => dispatch(hideSnackbar())}
          />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}
