import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import { MAP_LAYER_SOURCE } from '../const'
import { formatLayerUrl } from '../utils'
import TrackLayerPaint from './paint'

export default function TrackLayer(): ReactElement {
  const mode = 'full_rgi_line_geo'
  const params = 'type_habillage_id_libelle=Statut'
  return (
    <Source
      type="vector"
      url={formatLayerUrl(MAP_LAYER_SOURCE.track, mode, params)}
    >
      <Layer
        {...TrackLayerPaint.trackLayer}
        source-layer={MAP_LAYER_SOURCE.track}
      />

      <Layer
        {...TrackLayerPaint.trackNumberLayer}
        source-layer={MAP_LAYER_SOURCE.track}
      />
    </Source>
  )
}
