import { PORTAL_URL } from 'config/config'
import dgexLogo from 'assets/logos/dgex.svg'
import pdiLogo from 'assets/logos/pdi.svg'
import AccountMenu from './menu'

import './style.scss'

type Props = {
  appName: string;
}

export default function TopBar({ appName }: Props) {
  return (
    <header>
      <div className="logos">
        <a className="flex-center" href={PORTAL_URL.url}>
          <img alt={appName} src={dgexLogo} />
        </a>
        <img alt={appName} src={pdiLogo} />
      </div>
      <p>{appName}</p>
      <AccountMenu />
    </header>
  )
}
