import adminRegion from 'assets/mapstyles/region_admin.json'
import { FeatureCollection } from 'geojson'
import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'

import regionLayerPaint from './paint'

export default function RegionLayer(): ReactElement {

  return (
    <>
      <Source
        type="geojson"
        data={adminRegion as FeatureCollection}
      >
        <Layer {...regionLayerPaint.regionLayer} />
      </Source>

    </>

  )
}
