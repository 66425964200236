/* eslint-disable max-len */
import {
  MutableRefObject, ReactElement, Ref, useRef, useState,
} from 'react'
import ReactMapGL, {
  MapRef, ViewportProps, MapEvent,
} from 'react-map-gl'
import manualLabel from 'assets/icons/chevron.png'
import { updateViewport } from 'reducers/map/slices'
import lightOsmTheme from 'assets/mapstyles/light.json'
import { useAppDispatch, useAppSelector } from 'utils'
import { transformRequest } from 'services'
import TrackLayer from 'components/layers/track'
import SupplyLayer from 'components/layers/supply'
import RegionLayer from 'components/layers/region'
import { LAYER_NAMES } from 'components/layers/const'
import Toolbar from './toolbar'
import FeatureClickPopup from './popup'

import './style.scss'

export default function MapGL(): ReactElement {
  const mapRef: MutableRefObject<MapRef | undefined> | undefined = useRef()
  const { viewport } = useAppSelector(state => state.map)
  const [openPopup, setPopup] = useState(false)
  const [popupEvent, setPopupEvent] = useState<MapEvent>()
  const dispatch = useAppDispatch()
  const mapDOMRef = useRef(null)

  const onViewportChange = (newViewport: ViewportProps) => {
    dispatch(updateViewport({ ...newViewport, transitionDuration: 0 }))
  }

  const onFeatureClick = (e: MapEvent) => {
    if (e.features[0]
      && (e.features[0].layer.id === LAYER_NAMES.chantierLayer || `${LAYER_NAMES.chantierLayer}-point`)) {
      setPopupEvent(e)
      setPopup(true)
    }
  }

  return (
    <div className="map-gl" ref={mapDOMRef}>
      <ReactMapGL
        {...viewport}
        ref={mapRef as Ref<MapRef>}
        width="100%"
        height="100%"
        transformRequest={transformRequest}
        mapStyle={lightOsmTheme}
        onViewportChange={onViewportChange}
        onClick={onFeatureClick}
        onLoad={() => {
          const currentMap = mapRef?.current?.getMap()
          currentMap.loadImage(manualLabel, (error: any, image: any) => {
            if (error) return
            currentMap.addImage('manual', image, { sdf: true })
          })
        }}
      >
        <Toolbar />
        {openPopup && popupEvent && <FeatureClickPopup event={popupEvent} onClose={() => setPopup(false)} />}
        <TrackLayer />
        <RegionLayer />
        <SupplyLayer />
      </ReactMapGL>
    </div>
  )
}
