/* eslint-disable max-len */
import { FeatureCollection } from "geojson";
import { ReactElement } from "react";
import { Layer, Source } from "react-map-gl";
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import React, { useEffect, useState } from 'react';
import {
  midpoint,
  distance,
  bezierSpline,
  lineString,
  bearing,
  destination,
} from "@turf/turf";
import lines from "assets/data/line.json";
import points from "assets/data/point.json";
import centres from "assets/data/centre.json";

interface Checkbox {
  id: number;
  label: string;
  property: string;
  value: string | number;
}

export default function SupplyLayer(): ReactElement {
  const [filtered, setFiltered] = useState<any>([])
  const [checkboxes, setCheckboxes] = useState<Checkbox[]>([
    { id: 1, label: 'Lundi', property: 'lundi', value: 'X' },
    { id: 2, label: 'Mardi', property: 'mardi', value: 'X' },
    { id: 3, label: 'Mercredi', property: 'mercredi', value: 'X' },
    { id: 4, label: 'Jeudi', property: 'jeudi', value: 'X' },
    { id: 5, label: 'Vendredi', property: 'vendredi', value: 'X' },
    { id: 6, label: 'Samedi', property: 'samedi', value: 'X' },
    { id: 7, label: 'Dimanche', property: 'dimanche', value: 'X' },
  ]);

  /* const dataset = {
    type: "FeatureCollection",
    features: [],
  };
  lines.features.forEach((element) => {
    const bearings = bearing(
      element.geometry.coordinates[0],
      element.geometry.coordinates[1]
    );
    const distances = distance(
      element.geometry.coordinates[0],
      element.geometry.coordinates[1],
      { units: "miles" }
    );
    const midpoints = midpoint(
      element.geometry.coordinates[0],
      element.geometry.coordinates[1]
    );
    const leftSideArc =
      bearings + 90 > 180 ? -180 + (bearings + 90 - 180) : bearings + 90;
    const destinations = destination(midpoints, distances / 5, leftSideArc, {
      units: "kilometers",
    });
    const curvedLine = bezierSpline(
      lineString([
        element.geometry.coordinates[0],
        destinations.geometry.coordinates,
        element.geometry.coordinates[1],
      ])
    );
    dataset.features.push(curvedLine);
  }); */
  const updateMapFilter = () => {
    const filter: any[] = ['any']
    checkboxes.forEach((checkbox) => {
      const el = document.getElementById(`filterCheckbox${checkbox.id}`) as HTMLInputElement
      if (el?.checked) {
        filter.push(['==', ['get', checkbox.property], checkbox.value]);
      }
    });
    console.log(filter)
    setFiltered(filter)

  }
  return (
    <>
    <div style={{ padding: '14px', width: '250px', background: 'white', border: '1px solid lightgrey', borderRadius: '8px', margin: '20px'}}>
        {checkboxes.map((checkbox) => (
          <div key={checkbox.id}>
            <label>
              <input
                type="checkbox"
                id={`filterCheckbox${checkbox.id}`}
                onChange={updateMapFilter}
                style={{ marginRight: '5px'}}
                defaultChecked
              />
              {checkbox.label}
            </label>
          </div>
        ))}

        <p style={{ fontSize: '16px', marginTop: '20px', display: 'flex', alignItems: 'center'}}>
          <span style={{ width: '14px', height: '14px', background: 'black', display: 'inline-block', borderRadius: '2px', marginRight: '5px'}}></span>
          Train de machine
        </p>
        <p style={{ fontSize: '16px', display: 'flex', alignItems: 'center'}}>
          <span style={{ width: '14px', height: '14px', background: '#65B741', display: 'inline-block', borderRadius: '2px', marginRight: '5px'}}></span>
          Train inter BDSC
        </p>
        <p style={{ fontSize: '16px', display: 'flex', alignItems: 'center'}}>
          <span style={{ width: '14px', height: '14px', background: '#E21818', display: 'inline-block', borderRadius: '2px', marginRight: '5px'}}></span>
          Train du PTSC vers URAA
        </p>
      </div>
      <Source type="geojson" data={lines as FeatureCollection}>
        <Layer
          type={"line"}
          id={"lignes"}
          filter={filtered.length === 0 ? ["all",["!=",["get",""],"X"]] : filtered}
          layout={{
            "line-cap": "round",
            "line-join": "round",
          }}
          paint={{
            "line-color": ['case',
            ['==', ['get', 'type'], 'Train de machine'], 'black',
            ['==', ['get', 'type'], 'Train inter BDSC'], '#65B741',
            ['==', ['get', 'type'], 'Train du PTSC vers URAA'], '#E21818',
            '#154734',
          ],
            "line-width": {
              stops: [
                [6, 2],
                [8, 8],
              ],
            },
          }}
        />
      </Source>
      <Source type="geojson" data={points as FeatureCollection}>
        <Layer
          type={"symbol"}
          id={"points"}
          filter={filtered.length === 0 ? ["all",["!=",["get",""],"X"]] : filtered}
          layout={{
            "icon-image": "manual",
            "symbol-placement": "point",
            "icon-size": {
              stops: [
                [6, 0.1],
                [8, 0.3],
              ],
            },
            "icon-rotate": ["get", "angle"],
            "icon-allow-overlap": true,
          }}
          paint={{
            "icon-color": ['case',
            ['==', ['get', 'type'], 'Train de machine'], 'black',
            ['==', ['get', 'type'], 'Train inter BDSC'], '#65B741',
            ['==', ['get', 'type'], 'Train du PTSC vers URAA'], '#E21818',
            '#154734',
          ],
          }}
        />
      </Source>
      <Source type="geojson" data={centres as FeatureCollection}>
        <Layer
          type={"circle"}
          id={"points-2"}
          paint={{
          'circle-stroke-color': '#FFF',
          'circle-stroke-width': 1.5,
          "circle-opacity": 0.3,
          'circle-radius': {
            stops: [
              [6, 8],
              [8, 20],
            ],
          }
          }}
        />
      </Source>
    </>
  );
}
